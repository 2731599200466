export function Hotelapitoken(){
    return '23GzN0ZUp3XsHEiHYxXmVZX7WOGfNRb4VD424dl7-ukiMFmzmzQ8zgQUdNPzuN9xEw5h1MSQsql1BzQnsnetNaHStNyHiDyzJsU8T-HVNlcGTriQxt1jGxUKatkc33L-pbO6KtR4txHKjcqJS03jJhM2b5PhAvABnHfzlzvzNtk17TmiiYTkbTLNLEE4setJeBk9ziKIl4I-HQuCQF1mIo6MxAvcjfG18UwUbI12amcSdhBeJc9NJ1uYDrwN1pxXF9TN6sxaKsdV7BPjzeko19XLLNRrHCWLA7O955MbOV5xFepf-TzqUaZxBJJNWGIEMzwJnBYIQdShOMcwaEqiYJTXSPLuWZTfpbbySgHYSm3ytl7bMd4jpF8iy4XN-rtUXxXQeQHR5pQNT0tklOiH4r-G2og2cT7Mr07pVniRUYcAKg6TsL1BRT2wDFbxVrafl8UzYovel-IGV64fOFB9985fEPhxkuFBpz47crLzHh4FhR38cZrTJXBuslpN'
};

export function CustomerDomainName(){
    return 'https://system.alhijaztours.net/'
};
export function FlightSearchToken(){
    return '23GzN0ZUp3XsHEiHYxXmVZX7WOGfNRb4VD424dl7-ukiMFmzmzQ8zgQUdNPzuN9xEw5h1MSQsql1BzQnsnetNaHStNyHiDyzJsU8T-HVNlcGTriQxt1jGxUKatkc33L-pbO6KtR4txHKjcqJS03jJhM2b5PhAvABnHfzlzvzNtk17TmiiYTkbTLNLEE4setJeBk9ziKIl4I-HQuCQF1mIo6MxAvcjfG18UwUbI12amcSdhBeJc9NJ1uYDrwN1pxXF9TN6sxaKsdV7BPjzeko19XLLNRrHCWLA7O955MbOV5xFepf-TzqUaZxBJJNWGIEMzwJnBYIQdShOMcwaEqiYJTXSPLuWZTfpbbySgHYSm3ytl7bMd4jpF8iy4XN-rtUXxXQeQHR5pQNT0tklOiH4r-G2og2cT7Mr07pVniRUYcAKg6TsL1BRT2wDFbxVrafl8UzYovel-IGV64fOFB9985fEPhxkuFBpz47crLzHh4FhR38cZrTJXBuslpN'
};

export function ApiEndPoint(){
    return 'https://api.synchtravel.com'
    // return 'https://liveapi.synchtravel.com'
};
export function TransferApiToken(){
    return '23GzN0ZUp3XsHEiHYxXmVZX7WOGfNRb4VD424dl7-ukiMFmzmzQ8zgQUdNPzuN9xEw5h1MSQsql1BzQnsnetNaHStNyHiDyzJsU8T-HVNlcGTriQxt1jGxUKatkc33L-pbO6KtR4txHKjcqJS03jJhM2b5PhAvABnHfzlzvzNtk17TmiiYTkbTLNLEE4setJeBk9ziKIl4I-HQuCQF1mIo6MxAvcjfG18UwUbI12amcSdhBeJc9NJ1uYDrwN1pxXF9TN6sxaKsdV7BPjzeko19XLLNRrHCWLA7O955MbOV5xFepf-TzqUaZxBJJNWGIEMzwJnBYIQdShOMcwaEqiYJTXSPLuWZTfpbbySgHYSm3ytl7bMd4jpF8iy4XN-rtUXxXQeQHR5pQNT0tklOiH4r-G2og2cT7Mr07pVniRUYcAKg6TsL1BRT2wDFbxVrafl8UzYovel-IGV64fOFB9985fEPhxkuFBpz47crLzHh4FhR38cZrTJXBuslpN'
};
export function TransferCheckoutToken(){
    return '23GzN0ZUp3XsHEiHYxXmVZX7WOGfNRb4VD424dl7-ukiMFmzmzQ8zgQUdNPzuN9xEw5h1MSQsql1BzQnsnetNaHStNyHiDyzJsU8T-HVNlcGTriQxt1jGxUKatkc33L-pbO6KtR4txHKjcqJS03jJhM2b5PhAvABnHfzlzvzNtk17TmiiYTkbTLNLEE4setJeBk9ziKIl4I-HQuCQF1mIo6MxAvcjfG18UwUbI12amcSdhBeJc9NJ1uYDrwN1pxXF9TN6sxaKsdV7BPjzeko19XLLNRrHCWLA7O955MbOV5xFepf-TzqUaZxBJJNWGIEMzwJnBYIQdShOMcwaEqiYJTXSPLuWZTfpbbySgHYSm3ytl7bMd4jpF8iy4XN-rtUXxXQeQHR5pQNT0tklOiH4r-G2og2cT7Mr07pVniRUYcAKg6TsL1BRT2wDFbxVrafl8UzYovel-IGV64fOFB9985fEPhxkuFBpz47crLzHh4FhR38cZrTJXBuslpN'
};
export function CurrencyConverter(){
    return 'd9c44ce6f7a3ac2c11e79b9f'
};
export function StripeClientSecret(){
    // return 'sk_test_51N9pi8GwYlRApJ0wzqUOVIJBpCNJ1Lz7UcUjJoSdMU73WAVzoTXSJ1gfakCYThPWhnzWlKnLLQXugdB0s8dBFDJ500Beg22CmF'
    return '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'
};
export function ActivityToken(){
    return '23GzN0ZUp3XsHEiHYxXmVZX7WOGfNRb4VD424dl7-ukiMFmzmzQ8zgQUdNPzuN9xEw5h1MSQsql1BzQnsnetNaHStNyHiDyzJsU8T-HVNlcGTriQxt1jGxUKatkc33L-pbO6KtR4txHKjcqJS03jJhM2b5PhAvABnHfzlzvzNtk17TmiiYTkbTLNLEE4setJeBk9ziKIl4I-HQuCQF1mIo6MxAvcjfG18UwUbI12amcSdhBeJc9NJ1uYDrwN1pxXF9TN6sxaKsdV7BPjzeko19XLLNRrHCWLA7O955MbOV5xFepf-TzqUaZxBJJNWGIEMzwJnBYIQdShOMcwaEqiYJTXSPLuWZTfpbbySgHYSm3ytl7bMd4jpF8iy4XN-rtUXxXQeQHR5pQNT0tklOiH4r-G2og2cT7Mr07pVniRUYcAKg6TsL1BRT2wDFbxVrafl8UzYovel-IGV64fOFB9985fEPhxkuFBpz47crLzHh4FhR38cZrTJXBuslpN'
};
export function HajjApiToken(){
    return '23GzN0ZUp3XsHEiHYxXmVZX7WOGfNRb4VD424dl7-ukiMFmzmzQ8zgQUdNPzuN9xEw5h1MSQsql1BzQnsnetNaHStNyHiDyzJsU8T-HVNlcGTriQxt1jGxUKatkc33L-pbO6KtR4txHKjcqJS03jJhM2b5PhAvABnHfzlzvzNtk17TmiiYTkbTLNLEE4setJeBk9ziKIl4I-HQuCQF1mIo6MxAvcjfG18UwUbI12amcSdhBeJc9NJ1uYDrwN1pxXF9TN6sxaKsdV7BPjzeko19XLLNRrHCWLA7O955MbOV5xFepf-TzqUaZxBJJNWGIEMzwJnBYIQdShOMcwaEqiYJTXSPLuWZTfpbbySgHYSm3ytl7bMd4jpF8iy4XN-rtUXxXQeQHR5pQNT0tklOiH4r-G2og2cT7Mr07pVniRUYcAKg6TsL1BRT2wDFbxVrafl8UzYovel-IGV64fOFB9985fEPhxkuFBpz47crLzHh4FhR38cZrTJXBuslpN'
};
// 3f5b6c0c70393cf67ceac594
// d9c44ce6f7a3ac2c11e79b9f