import React from "react";
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faMobileScreenButton} from '@fortawesome/free-solid-svg-icons'
import logo from '../../Components/Navbar/logo.png'
function Footer(){
    return(
    <>
       <section className="footer">
        <div className="opacity">
          <div className="container">
                <div className="row input">
                    <div className="col-8">
                      <div className="image p-1 ">
                        <img className="mb-1 mt-1 footer-logo"  src={logo}  alt="Logo" />
                      </div>
                    </div>
                      <div className="col-md-4 col-sm-6 mt-5 input">
                          <input className="mt-5 pt-1 pb-1 footer-input"  type="email" placeholder="Enter Your Email" />
                      </div>
                   </div>
                <div className="row text-dark">
                    <div className="col-md-8">
                      <div className="row mt-4">
                          <div className="col-md-4">
                              <h4>Company</h4>
                              <p>About </p>
                              <p>Jobs</p>
                              <p>List your property</p>
                              <p>Partnerships </p>
                          </div>
                          <div className="col-md-4">
                              <h4>Explore</h4>
                              <p>Hotels </p>
                              <p>Flights</p>
                              <p>Car Rentals </p>
                              <p>Package Bookings </p>
                              <p>Activities</p>
                          </div>
                          <div className="col-md-4" >
                              <h4>Policies</h4>
                                <ul className="courses-link-list">
                                      <li><NavLink className='nav-link' to='/terms_and_conditions'>
                                      {/* <i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i> */}
                                      Term and conditions</NavLink></li>
                                      <li><NavLink className='nav-link' to='/privacy_policy'>
                                      {/* <i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i> */}
                                      Privacy Policy</NavLink></li>
                                      <li><NavLink className='nav-link' to='/faqs'>
                                      {/* <i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i> */}
                                      Faqs</NavLink></li>

                                      <div >
                          <ul className="d-flex">
                            <li></li>
                            <li></li>
                          </ul>
                        </div>


                                     
                                    </ul>
                                      {/* <li><NavLink className='nav-link' to='/contact-us'>
                                      <i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>
                                      Contact Us</NavLink></li> */}
                          </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-12">
                        <br />
                        <ul>
                          <li><i><FontAwesomeIcon icon={faLocationDot}/></i> 40 feet road samijabad #1 Multan Pakistan</li>
                          <li> <i><FontAwesomeIcon icon={faMobileScreenButton}/></i>+92303-6060601</li>
                          <li><i><FontAwesomeIcon icon={faEnvelope}/></i> 
                          admin@umrahshop.com</li>
                        </ul>
                        <h4>Follow Us On</h4>
                       <ul className="d-flex me-3 mt-2">
                        <li><a href="https://www.instagram.com/umrahshop_com/profilecard/?igsh=b2RheXJ2MThudmx6" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                        <li><a href="https://www.facebook.com/umrahshopcom?mibextid=LQQJ4d" target="_blank"><i class="fa-brands fa-facebook"></i></a></li>
                       </ul>


                        

                       
                        </div>
                    </div>
                </div>
          </div>
          <div className="col-12 text-center mt-4" >
                  <span> admin@umrahshop.com | Copyright 2024 All Right Reserved</span>
          </div>
        </div>
      </section>
    </>        
    );
}

export default Footer;